<!--地标快报-->
<template>
  <div class="wallBulletinDiv">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <!--新闻列表-->
    <div class="news-div listDivMinHeight">
      <div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
        <div class="notice-item-img"><img :src="item.contentImg" alt/></div>
        <div class="rightContent">
          <div class="notice-item-title" :title="item.contentTitle">{{ item.contentTitle }}</div>
          <div class="notice-item-content">
            <text-ellipsis :text="item.contentOverview" :height="50" style="width: 100%">
              <template slot="more">...</template>
            </text-ellipsis>
          </div>
          <div class="notice-item-date flexAlignItemCenter">
            <img src="@/assets/img/giEdifice/grayTimer.svg" alt="" class="imgClass">
            {{ item.contentEditTime }}
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import {getPageByCategoryId} from '@/utils/commonApi.js'
import textEllipsis from '@/components/textellipsis'
export default {
  name: 'wallBulletin',
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标资讯',
        },
        {
          name: '地标快报',
          path: '/giInformation/wallBulletin'
        },
      ],
      noticeList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
      categoryId: "", //接收路由传过来的栏目id
    }
  },
  mounted () {
    this.categoryId = this.$route.query.categoryId
    this.getList()
  },
  methods: {
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    getList() {
      let params = {
        ...this.$route.query,
        current: this.page,
        size: this.pageSize,
      }
      getPageByCategoryId(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.tableTotal = res.data.data.total
          this.noticeList = res.data.data.records
          /*日期显示年月日*/
          for (let i = 0; i < this.noticeList.length; i++) {
            this.noticeList[i].contentEditTime = this.noticeList[i].contentEditTime.substring(0,10)
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
    // 跳转详情
    toDetail(news) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '详情',
        path: '/common/newsDetail'
      })

      this.$router.push({
        path: '/common/newsDetail',
        query: {
          pathObj:pathObj,
          contentId: news.contentId,
          categoryId: this.categoryId,
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .wallBulletinDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .news-div {
    min-height: 487px;
    .notice-item {
      width: 1160px;
      margin: 0px auto 29px;
      padding: 13px 16px;
      background: #FFFFFF;
      border-radius: 5px;
      height: 161px;
      .notice-item-img {
        float: left;
        img {
          width: 270px;
          height: 160px;
        }
      }
      .rightContent {
        text-align: left;
        margin-left: 311px;
        margin-top: 17px;
        .notice-item-title {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 27px;
          overflow: hidden;//超出隐藏
          text-overflow: ellipsis;//显示省略号
          white-space: nowrap;//禁止文本自动换行
        }
        .notice-item-content {
          margin-top: 12px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .notice-item-date {
          margin-top: 18px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ABB2BF;
          line-height: 21px;
          .imgClass {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
    .notice-item:hover {
      cursor: pointer;
    }
  }
  .page {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1366px) {
    .wallBulletinDiv {
      text-align: center;
      background-color: #f8f9fa !important;
    }
    .breadTop {
      width: 1200px;
      margin: auto;
    }
    .news-div {
      min-height: 487px;
      .notice-item {
        width: 1160px;
        margin: 0px auto 29px;
        padding: 13px 16px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 161px;
        .notice-item-img {
          float: left;
          img {
            width: 270px;
            height: 160px;
          }
        }
        .rightContent {
          text-align: left;
          margin-left: 311px;
          margin-top: 17px;
          .notice-item-title {
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
          }
          .notice-item-content {
            margin-top: 12px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .notice-item-date {
            margin-top: 18px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ABB2BF;
            line-height: 21px;
            .imgClass {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
      .notice-item:hover {
        cursor: pointer;
      }
    }
    .page {
      margin-bottom: 20px;
    }
  }
</style>
